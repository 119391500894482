import { HttpStatusCode } from "@whyuz/data";
import { Variables } from "graphql-request";
import { Error_Code, SortDirection } from "../codegen/graphql.ts";
import { GQLFieldErrorMessageDictionary } from "./dictionaries.ts";

export enum GQLErrorStatusCode { //extends HttpStatusCode {
  VALIDATION_ERROR = 9000,
  DATA_INTEGRITY_ERROR = 9001,
  TENANT_UNKNOWN = 9002,
  TOKEN_EXPIRED = 9003,
  ENTITY_NOT_FOUND = 9004,
  MAIL_AUTHENTICATION_ERROR = 9005,
}

export type GQLFieldErrorType = ErrorMessageType & {
  entity?: string;
  field?: string;
  value?: string | null;
};

export type GQLError = {
  message: string;
  fieldErrors: GQLFieldErrorMessageDictionary;
  isUncontrolledError: boolean;
  statusCode?: GQLErrorStatusCode | HttpStatusCode;
};

export type GQLQueryInformation<T> = {
  hasEverBeenExecuted: boolean;
  isLoading: boolean;
  data?: T;
  error?: GQLError;
};

export type GQLQueryContext<T, V extends Variables> = {
  variables?: V;
  onCompleted?: (data?: T) => void;
  onError?: (error: GQLError) => void;
  baseURL?: string;
};

export type GQLSort<T> = {
  field: string | keyof T;
  sortDirection: SortDirection;
};

export type ErrorType = "error" | "warning" | "info";

export type ErrorMessageType = {
  error: Error_Code;
  message?: string;
  type?: ErrorType;
};
